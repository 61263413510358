import { createStore } from 'vuex'
import Cookie from 'vue-cookies';
import common from '@/common/common.js'

export default createStore({
    state: {
        islogin: common.basic.chstr2int(Cookie.get('islogin')),
        langcur: common.basic.chstr2int(Cookie.get('lang'), 1),
        langdic: common.json.sync('/include/dic2.json'),
        config: common.json.sync('/include/config.json'),
        pngpre: 'data:image/png;base64,',
        wh: document.body.clientHeight,// window.innerHeight,// document.body.clientHeight;
        ww: document.body.clientWidth,//window.innerWidth,// document.body.clientWidth;
        menurecent: [],
        menuofften: [],
        isdragactive: false,
        pagesize: common.basic.chstr2int(Cookie.get('pagesize'), 15),
        upurl: Cookie.get('upurl'),
        mystyle: (Cookie.get('mystyle') == null || Cookie.get('mystyle') == 'null') ? {
            adminbg: '/include/images/wallpaper.jpg',
            adminface: '/include/images/face.png',
            windowstyle: 1,
            navstyle: 1,
            taskstyle: 2,
            tabtaskstyle: 1,
            shortcut: 1
        } : Cookie.get('mystyle'),
        cameraid: ''//����ͷ
    },
    getters: {
        istasktb: state => state.mystyle.windowstyle == 2 || state.mystyle.taskstyle == 1 || state.mystyle.taskstyle == 3,
        isnavtb: state => state.mystyle.navstyle == 1 || state.mystyle.navstyle == 3,
        taskpos: state => state.mystyle.windowstyle == 2 ? (Number(state.mystyle.tabtaskstyle) || 1) + 4 : Number(state.mystyle.taskstyle),
        menucur: state => state.mystyle.shortcut == 1 ? state.menurecent : state.menuofften,
    },
    mutations: {
        SET_ISLOGIN(state, v) {
            if (v != state.islogin) {
                state.islogin = v;
                Cookie.set('islogin', v, -1);
            }

        },
        SET_LANGCUR(state, v) {
            let _lang = 0;
            if (v) {
                _lang = v;
            }
            else {
                let clang = common.basic.chstr2int(Cookie.get('lang'));
                if (clang == 0) {
                    _lang = (navigator.language || navigator.browserLanguage || navigator.userLanguage).toLowerCase() == "zh-cn" ? 1 : 2;
                }
            }
            if ((_lang > 0) && (state.langcur != _lang)) {
                state.langcur = _lang;
                Cookie.set('lang', _lang, -1);
            }

        },
        SET_MENURECENT(state, v) {
            if (v != state.menurecent) {
                state.menurecent = v;
            }

        },
        SET_MENUOFFTEN(state, v) {
            if (v != state.menuofften) {
                state.menuofften = v;
            }

        },
        SET_ISDRAGACTIVE(state, v) {
            if (v != state.isdragactive) {
                state.isdragactive = v;
            }

        },
        SET_WINDOWSIZE(state) {
            state.wh = window.innerHeight;// document.body.clientHeight;
            state.ww = window.innerWidth;// document.body.clientWidth;
        },
        SET_PAGESIZE(state, v) {
            if (v != state.pagesize) {
                state.pagesize = v;
                Cookie.set('pagesize', v, -1);
            }

        },
        SET_UPURL(state, v) {
            if (v != state.upurl) {
                state.upurl = v;
                Cookie.set('upurl', v, -1);
            }

        },
        SET_MYSTYLE(state, v) {
            if (JSON.stringify(v) != JSON.stringify(state.mystyle)) {
                Object.assign(state.mystyle, v);
                Cookie.set('mystyle', state.mystyle, -1);
                let jsondata = {
                    'postdata': { 'act': 'mystyle', "data": { "styles": state.mystyle } }, "msg": ""
                };
                common.post(jsondata, () => { });

            }

        },
        SET_CAMERAID(state, v) {
            if (state.cameraid != v) {
                state.cameraid = v;
            }
        }
    },
    actions: {
        setislogin({ commit }, v) {
            commit('SET_ISLOGIN', v);
        },
        setlangcur({ commit }, v) {
            commit('SET_LANGCUR', v);
        },
        setmenurecent({ commit }, v) {
            commit('SET_MENURECENT', v);
        },
        setmenuofften({ commit }, v) {
            commit('SET_MENUOFFTEN', v);
        },
        setupurl({ commit }, v) {
            commit('SET_UPURL', v);
        },
        setmystyle({ commit }, v) {
            commit('SET_MYSTYLE', v);
        }
    }
});
