<template>
    <div class="swiperwrap" v-finger:swipe="touchswipe" @mouseover="mystop=true" @mouseout="mystop=false">
        <ul class="swiperbg">
            <li v-for="(item,index) in infolist" :key="index">
                <template v-if="c_options.type=='image'">
                    <img :src="item.img||item" class="swiperimg" />
                </template>
                <template v-else>
                    <div :class="[c_options.isfull?'editor2':'editor']"><span v-html="$common.langinfo(item.content,item.content_en)"></span></div>
                </template>
            </li>
        </ul>
        <transition-group :name="tranname" tag="ul" class="swiperbox">
            <li v-for="(item,index) in infolist" :key="index" v-show="index==myindex">
                <template v-if="c_options.type=='image'">
                    <img :src="item.img||item" class="swiperimg" />
                </template>
                <template v-else>
                    <div :class="[c_options.isfull?'editor2':'editor']"><span v-html="$common.langinfo(item.content,item.content_en)"></span></div>
                </template>
            </li>

        </transition-group>
        <div class="swiperpoint2" v-if="c_options.ispoint && itemmax>1">
            <a v-for="(item,index) in infolist" :key="item.id" href="#" @click="swipergo(index)" :class="{cs:index==myindex}"></a>
        </div>
    </div>

</template>

<script>
    export default {
        name: 'ffswiper',
        data() {
            return {
                lbinterval: null,
                myindex: 0,
                mystop: false,
                optionsdefault: {
                    'type': 'image', 'isautoplay': true, 'isstop': false, 'duration': 3000, 'ispoint': false, 'isfull': false//isfull是内容格式时是否有padding
                }
            }
        },
        props: {
            infolist: {
                type: Array,
                default: () => []
            },
            options: {
                type: Object,
                default: () => { }
            },
            indexcur: {
                type: Number,
                default: 0
            },
            direct: {
                type: Number,
                default: 1
            }
        },
        computed: {
            c_options: function () {
                return Object.assign({}, this.optionsdefault, this.options);
            },
            itemmax: function () {
                return this.infolist.length;
            },
            tranname: function () {
                return this.direct == 1 ? 'swiperleft' : 'swiperright'
            },
            isstop: function () {
                return this.mystop || this.c_options.isstop;
            }
        },
        methods: {
            updateindexcur: function (v, direct) {
                this.myindex = v >= this.itemmax ? 0 : v < 0 ? this.itemmax - 1 : v;
                this.$emit('update:indexcur', v);
                if (direct != undefined) {
                    this.$emit('update:direct', direct);
                }
            },
            autoplay: function () {
                if (this.c_options.isautoplay && this.itemmax > 1) {
                    this.lbinterval = window.setInterval(() => {
                        if (!this.isstop) {
                            this.updateindexcur(this.direct == 1 ? this.myindex + 1 : this.myindex - 1);
                        }
                    }, this.c_options.duration);
                }
            },
            touchswipe: function (e) {
                if (e.direction == "Left") {
                    this.updateindexcur(this.myindex + 1, 1);
                }
                else {
                    if (e.direction == "Right") {
                        this.updateindexcur(this.myindex - 1, 2);
                    }
                }
            },
            swipergo: function (v) {
                let direct = v > this.myindex ? 1 : 2;
                this.updateindexcur(v, direct)
            }
        },

        mounted() {
            this.myindex = this.indexcur;
            this.autoplay();
        },
        beforeDestroy() {
            window.clearInterval(this.lbinterval);
        }
    }
</script>
