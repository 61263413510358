import axios from 'axios'
// 创建axios实例

let baseurl = "";
if (process.env.NODE_ENV == 'development') {//开发环境
    baseurl = "/api";
} else if (process.env.NODE_ENV == 'debug') {
    baseurl = "/api";
} else {//正式环境
    baseurl = "";
}

const ajax = axios.create({
    baseURL: baseurl, // api的base_url
    timeout: 15000 // 请求超时时间
})

// request拦截器
ajax.interceptors.request.use(config => {
    config.data = JSON.stringify(config.data);
    //config.data = qs.stringify(config.data);
    config.headers = {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'//x-www-form-urlencoded   json;charset=utf-8
    }
    return config
}, error => {
    Promise.reject(error)
})

// respone拦截器
ajax.interceptors.response.use(
    response => {
        if (response.status !== 200) {
            return Promise.reject(response.status)
        } else {
            return response.data;
        }
    },
    error => {
        return Promise.reject(error)
    }
)

export default ajax
