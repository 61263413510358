<template>
    <el-upload :class="['ff-uploader',isimg?'ff-img':'']" :action="action" :accept="accept" ref="upload" :multiple="false" :data="c_updata" :show-file-list="false" v-bind="$attrs" :on-success="uploaded"
               :before-upload="beforeupload" :with-credentials="true" :on-progress="uploading" :on-error="uploadfail">
        <template v-if="isimg">
            <img v-if="fileurl" :src="fileurl" class="upimg">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </template>
        <span v-else class="upbtn">{{uptitle}}</span>
        <div v-show="perc<100" class="imgupmask"><div class="imgupprogress"><el-progress :percentage="perc" :show-text="false"></el-progress></div></div>

    </el-upload>
</template>

<script>
    export default {
        name: 'ffupload',
        data() {
            return {
                perc: 100
            }
        },
        props: {
            fileurl: {
                type: String,
                default: ''
            },
            updata: {
                type: Object,
                defalut: () => { }
            },
            uptitle: {
                type: String,
                default: '上传'
            }
        },
        computed: {
            c_updata: function () {
                let doptions = {
                    "isup": 1, "width": 0, "height": 0, "wmflag": 0, "folder": "", "mtkind": 0, "mtneed": 0, "filesize": 0, "mediatype": "img", "isfront": 1
                }
                let _updata = Object.assign({}, doptions, this.updata);
                return _updata;
            },
            action: function () {
                return (process.env.NODE_ENV == 'development' ? '/api' : (process.env.NODE_ENV == 'debug') ? '/api' : '') + '/api.ashx';
            },
            accept: function () {
                let _accept = 'image/*';
                switch (this.updata.mediatype) {
                    case 'attach':
                        _accept = ".rar,.zip,.doc,.docx,.pdf,.xls,.xlsx,.ppt,.pps,.pptx,.ppsx,.swf,.jpg,.jpeg,.bmp,.png,.gif";
                        break;
                    case 'zip':
                        _accept = ".rar,.zip";
                        break;
                    case 'audio':
                        _accept = '.mp3'
                        break;
                    case 'video':
                        _accept = '.mp4'
                        break;
                    case 'csv':
                        _accept = '.csv'
                        break;
                    case 'pdf':
                        _accept = '.pdf'
                        break;
                    case 'img':
                    case 'face':
                    case 'avatar':
                    case 'avatar2':
                        _accept = ".jpg,.jpeg,.bmp,.png,.gif";
                        break;
                }
                return _accept;
            },
            isimg: function () {
                return (this.updata.mediatype == 'img') || (this.updata.mediatype == 'face') || (this.updata.mediatype == 'avatar') || (this.updata.mediatype == 'avatar2');
            }
        },
        methods: {
            uploading: function (e, file) {
                this.$emit('update:fileurl', URL.createObjectURL(file.raw));
                this.perc = file.percentage;
            },
            uploaded: function (res) {
                this.perc = 100;
                if (res.bv == 1) {
                    this.$emit('update:fileurl', res.msg);
                }
                else {
                    this.uploadfail(res.msg);
                }

            },
            uploadfail: function (err) {
                this.$common.alert(err);
                this.$emit('update:fileurl', '');
            },
            beforeupload: function (file) {
                let _filesize = this.updata.filesize || 200;
                const istoobig = file.size > _filesize * 1024 * 1024;
                if (istoobig) {
                    this.$common.alert(this.$common.dicword('errfilesize') + _filesize + 'M');
                }
                else {
                    this.perc = 0;
                }
                return !istoobig;
            }
        }

    }
</script>
