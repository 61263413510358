<template>
    <div class="ffuploadimg" v-viewer>
        <div v-for="(item,index) in imgs" :key="item.uid" class="imgitem" v-if="$common.basic.isarrayexist(imgs)">
            <div class="imgitembox">
                <img :src="item.url" />
                <span v-if="isupload" class="itemdel" @click="uploaddel(index)"><i class="iconfont icon-cuo"></i></span>
                <div v-show="item.perc<100" class="imgupmask"><div class="imgupprogress"><el-progress :percentage="item.perc" :show-text="false"></el-progress></div></div>
            </div>
        </div>

        <el-upload v-if="isupload" class="ff-uploader ff-img" :action="action" :accept="accept" ref="upload" :multiple="true" :data="c_updata" :show-file-list="false" v-bind="$attrs" :on-success="uploaded"
                   :before-upload="beforeupload" :with-credentials="true" :on-progress="uploading" :on-error="uploadfail">
            <i class="el-icon-plus avatar-uploader-icon" :title="$common.dicword(uptitle)"></i>
        </el-upload>
    </div>

</template>

<script>
    export default {
        name: 'ffuploadimgs',
        data() {
            return {
                imgs: [],
                accept: '.jpg,.jpeg,.bmp,.png,.gif'
            }
        },
        props: {
            value: {
                type: String,
                default: ''
            },
            updata: {
                type: Object,
                defalut: () => { }
            },
            uptitle: {
                type: String,
                default: 'uploadimgs'
            },
            isupload: {
                type: Boolean,
                default: true
            }
        },
        computed: {
            c_updata: function () {
                let doptions = {
                    "isup": 1, "width": 0, "height": 0, "wmflag": 0, "folder": "", "mtkind": 0, "mtneed": 0, "filesize": 0, "mediatype": "img", "isfront": 1
                }
                let _updata = Object.assign({}, doptions, this.updata);
                return _updata;
            },
            action: function () {
                return (process.env.NODE_ENV == 'development' ? '/api' : (process.env.NODE_ENV == 'debug') ? '/api' : '') + '/api.ashx';
            }
        },
        methods: {
            uploading: function (e, file) {
                let item = this.$common.basic.fffind(this.imgs, "uid", file.uid);
                item.url = URL.createObjectURL(file.raw);
                item.perc = file.percentage;
            },
            uploaded: function (res, file) {
                if (res.bv == 1) {
                    let item = this.$common.basic.fffind(this.imgs, "uid", file.uid);
                    item.url = res.msg;
                    item.perc = 100;
                    this.upimgs();
                }
                else {
                    this.uploadfail(res.msg, file);
                }

            },
            uploadfail: function (msg, file) {
                let itemindex = this.imgs.findIndex(item => item.uid == file.uid);
                if (itemindex >= 0) {
                    this.imgs.splice(itemindex, 1);
                    this.$common.alert(msg);
                }
                this.upimgs();
            },
            uploaddel(index) {
                this.imgs.splice(index, 1);
                this.upimgs();
            },
            upimgs() {
                let _value = '';
                this.$common.each(this.imgs, item => {
                    _value += "," + item.url;
                });
                if (_value != "") { _value = _value.substring(1); }
                this.$emit('update:value', _value);
            },
            init() {
                this.imgs = [];
                if (!this.$common.basic.chempty(this.value)) {
                    let arrimg = this.value.split(',');
                    arrimg.forEach((item, index) => {
                        this.imgs.push({ 'url': item, 'perc': 100, 'uid': 0 - index });
                    });
                }
            },
            beforeupload: function (file) {
                let _filesize = this.c_updata.filesize || 200;
                const istoobig = file.size > _filesize * 1024 * 1024;
                if (istoobig) {
                    this.$common.alert(this.$common.dicword('errfilesize') + _filesize + 'M');
                }
                else {
                    this.imgs.push({ 'url': '', 'perc': 0, 'uid': file.uid });
                }
                return !istoobig;
            }
        },
        mounted() {
            this.init();
        }

    }
</script>
