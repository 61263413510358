import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export default {
  __name: 'App',
  setup(__props) {
    onMounted(() => {
      let aprevent = (e, href) => {
        href = href ? href.substring(href.length - 1) : '';
        if (href == "#") {
          if (e.preventDefault) {
            e.preventDefault();
          } else {
            e.returnValue = false;
          }
          //return false;
        }
      };

      window.addEventListener('click', e => {
        if (e.target.tagName === 'A') {
          aprevent(e, e.target.href);
        } else {
          if (e.target.parentNode.tagName === 'A') {
            aprevent(e, e.target.parentNode.href);
          } else {
            if (e.target.parentNode.parentNode) {
              if (e.target.parentNode.parentNode.tagName === 'A') {
                aprevent(e, e.target.parentNode.parentNode.href);
              }
            }
          }
        }
      });
    });
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createBlock(_component_router_view);
    };
  }
};