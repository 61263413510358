<template>
    <router-link v-if="$common.basic.chempty(info.url)" :to="forceurl||info.x_url" :title="linktype=='btn'?'':info.title">
        <span v-if="linktype=='image'" class="imglinkzoom">
            <img :src="info.cover" />
            <span class="imgzoom"><img :src="info.cover" /></span>
        </span>
        <template v-else-if="linktype=='content'">
            {{$common.basic.strgetlen($common.langinfo( info.content,info.content_en),info.x_lencontent)}}
        </template>
        <template v-else-if="linktype=='btn'">
            {{info.x_btn}}
        </template>
        <template v-else>
            {{$common.basic.strgetlen($common.langinfo( info.title,info.title_en),info.x_len)}}
        </template>
    </router-link>
    <a v-else href="info.url" target="_blank" :title="linktype=='btn'?'':info.title">
        <img v-if="linktype=='image'" :src="info.cover" />
        <template v-else-if="linktype=='content'">
            {{$common.basic.strgetlen($common.langinfo( info.content,info.content_en),info.x_lencontent)}}
        </template>
        <template v-else-if="linktype=='btn'">
            {{info.x_btn}}
        </template>
        <template v-else>
            {{$common.basic.strgetlen($common.langinfo( info.title,info.title_en),info.x_len)}}
        </template>
    </a>
</template>

<script>
    export default {
        name: 'fflink',
        props: {
            info: {
                type: Object,
                default: () => { }
            },
            linktype: {
                type: String,
                default: ''
            },
            forceurl: {
                type: String,
                default: ''
            }
        }
    }
</script>
