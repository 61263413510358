import { createApp } from 'vue'
import Ele from 'element-plus'
import 'element-plus/dist/index.css'
import App from '@/App.vue';
import router from '@/router'
import store from '@/store'
import AlloyFinger from 'alloyfinger'
import AlloyFingerPlugin from 'alloyfinger/vue/alloy_finger_vue'

import NProgress from 'nprogress'; // Progress 进度条
import 'nprogress/nprogress.css'; // Progress 进度条样式

import 'babel-polyfill'
import 'viewerjs/dist/viewer.css'
import Viewer from 'v-viewer'

import VMdEditor from '@kangc/v-md-editor/lib/codemirror-editor';
import '@kangc/v-md-editor/lib/style/codemirror-editor.css';
import githubTheme from '@kangc/v-md-editor/lib/theme/github.js';
import '@kangc/v-md-editor/lib/theme/style/github.css';

// highlightjs
import hljs from 'highlight.js';

// codemirror 编辑器的相关资源
import Codemirror from 'codemirror';
// mode
import 'codemirror/mode/markdown/markdown';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/mode/css/css';
import 'codemirror/mode/htmlmixed/htmlmixed';
import 'codemirror/mode/vue/vue';
// edit
import 'codemirror/addon/edit/closebrackets';
import 'codemirror/addon/edit/closetag';
import 'codemirror/addon/edit/matchbrackets';
// placeholder
import 'codemirror/addon/display/placeholder';
// active-line
import 'codemirror/addon/selection/active-line';
// scrollbar
import 'codemirror/addon/scroll/simplescrollbars';
import 'codemirror/addon/scroll/simplescrollbars.css';
// style
import 'codemirror/lib/codemirror.css';

import common from '@/common/common'
import Cookie from 'vue-cookies';

const app = createApp(App);
app.config.productionTip = true;
app.config.globalProperties.$common = common;
app.config.globalProperties.$Cookie = Cookie;

VMdEditor.Codemirror = Codemirror;

VMdEditor.use(githubTheme, {
    Hljs: hljs,
});

app.use(VMdEditor);

app.use(Ele);


app.use(AlloyFingerPlugin, {
    AlloyFinger
});

app.use(Viewer, {
    defaultOptions: {
        zIndex: 9999,
        navbar: false,
        title: false,
        filter: (Viewer) => (Viewer.className.indexOf("noviewer") < 0)
        /*toolbar: {
    //zoomIn: 4,
    //zoomOut: 4,
    //oneToOne: 4,
    //reset: 4,
    prev: 1,
    play: {
    show: 4,
    size: 'large',
    },
    next: 1,
    //rotateLeft: 4,
    //rotateRight: 4,
    //flipHorizontal: 4,
    //flipVertical: 4,
    } */
    }
});


const requireComponent = require.context(
    // 其组件目录的相对路径
    './components/base',
    // 是否查询其子目录
    false,
    // 匹配基础组件文件名的正则表达式
    /ff\w+\.(vue|js)$/
);

requireComponent.keys().forEach(fileName => {
    // 获取组件配置
    const componentConfig = requireComponent(fileName)

    // 获取和目录深度无关的文件名
    const componentName = fileName
        .split('/')
        .pop()
        .replace(/\.\w+$/, '');
    // 全局注册组件
    app.component(
        componentName,
        // 如果这个组件选项是通过 `export default` 导出的，
        // 那么就会优先使用 `.default`，
        // 否则回退到使用模块的根。
        componentConfig.default || componentConfig
    )
});

const clickoutsideContext = '@@clickoutsideContext';
app.directive('Clickoutside', {
    bind(el, binding, vnode) {
        const documentHandler = (e) => {
            if (vnode.context && !el.contains(e.target)) {
                vnode.context[el[clickoutsideContext].methodName]();
            }
        };
        el[clickoutsideContext] = {
            documentHandler,
            methodName: binding.expression,
            arg: binding.arg || 'click',
        };
        document.addEventListener(el[clickoutsideContext].arg, documentHandler);
    },
    update(el, binding) {
        el[clickoutsideContext].methodName = binding.expression;
    },
    unbind(el) {
        document.removeEventListener(
            el[clickoutsideContext].arg,
            el[clickoutsideContext].documentHandler);
    }
});

NProgress.configure({ ease: 'ease', speed: 500 })

NProgress.start();
NProgress.done();

router.beforeEach((to, from, next) => {
    NProgress.start();
    next();
});

router.afterEach(() => {
    NProgress.done();
})

app.directive('title', {
    inserted: function (el) {
        document.title = el.dataset.title || '';
    }
})


app.use(router);
app.use(store);
app.mount('#app')
