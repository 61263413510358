import { createRouter, createWebHistory } from 'vue-router'

import index from '@/views/index.vue'

const routerules = [

    {
        path: '/',
        component: index
    },
    {
        path: '/:root',
        component: index
    },
    {
        path: '/:root/:id(\\d+)',
        component: index
    },
    {
        path: '/:root/:id(\\d+)/:subid(\\d+)',
        component: index
    },
    {
        path: '/:root/:sub',
        component: index
    },
    {
        path: '/:root/:sub/:id(\\d+)',
        component: index
    },
    {
        path: '/:root/:sub/:ex',
        component: index
    },
    {
        path: '/:root/:sub/:id(\\d+)/:subid(\\d+)',
        component: index
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    },
    routes: routerules
})

export default router