import store from '@/store'
import ajax from '@/common/ajax.js'
import basic from '@/common/basic.js'
import eleui from '@/common/eleui.js'
import validate from '@/common/validate.js'
import json from '@/common/json.js'

import 'babel-polyfill'

const FFAPI = "fea";
const FFACT = "fea";
const common = {};

common.basic = basic;//基本方法
common.json = json;//json操作
common.validate = validate.check;//格式验证

////////////////////提示方法
common.alert = (msg, msgtype = 'error', duration = 3) => eleui.alert(common.dicword(basic.mydecode(msg)), msgtype, duration);
common.success = (msg) => common.alert(msg, 'success');
common.loading = (text) => eleui.loading(common.dicword(text));
common.loadingclose = (obj) => eleui.loadingclose(obj);
common.confirm = (msg, callbackyes, callbackno, title = '', btnyes = '', btnno = '') => eleui.confirm(common.dicword(msg), callbackyes, callbackno, title || common.dicword('confirminfo'), btnyes || common.dicword('confirm'), btnno || common.dicword('cancel'));

////////////////////实用方法
common.dicword = (name, isfield = false) => store.state.langdic[name] ? (common.langinfo(store.state.langdic[name][0], store.state.langdic[name][1] || name, isfield)) : (common.langinfo(name, name, isfield));
common.dicwordcompare = (name, v) => store.state.langdic[name][0] == v || store.state.langdic[name][1] == v;

common.langtitle = (v, isfield = false) => {
    let temp = v;
    if (v) {
        let titlecn = v;
        let titleen = "";
        if (v.indexOf('_') > 0) {
            let vs = v.split('_');
            titlecn = vs[0];
            titleen = vs[1];
        }
        temp = common.langinfo(titlecn, titleen, isfield);
    }

    return temp;
}

common.langcn = () => (!store.state.config.isuseen) || (store.state.langcur == 1);

common.langinfo = (v1, v2, isfield = false) => {
    v1 = basic.strunformat(v1);
    v2 = basic.strunformat(v2);

    return (common.langcn() ? basic.tranempty(v1, v2 || '') : basic.tranempty(v2, v1 || '')) + (isfield ? common.langcn() ? '：' : ':' : '');
};
common.chgender = (v) => common.dicword(basic.chgender(v));
common.chstr2genderint = (v) => common.dicwordcompare('male', v) ? 1 : common.dicwordcompare('female', v) ? 2 : 0;
common.chint2bool = (v) => common.dicword(basic.chint2bool(v));
common.chstr2boolint = (v) => common.dicwordcompare('yes', v) ? 1 : 0;

common.each = (arr, callback) => {
    if (basic.isarrayexist(arr)) {
        arr.forEach((item, index) => {
            callback(item, index);
        });
    }
};
common.getop = (opdata, tn) => {
    return basic.fffind(opdata, "tn", tn);
}

common.getopdata = (opdata, tn) => {
    return common.getop(opdata, tn).data;
}

common.getoptitle = (opdata, tn, id) => {
    return common.getoptiontitle(common.getop(opdata, tn).data, id);
}


common.getoptiontitle = (op, id, fn = 'title') => {
    if (basic.isarrayexist(op)) {
        let cop = op.find(item => item.id == id);
        return cop ? cop[fn] : '';
    }
    else {
        return '';
    }
}

common.checksv = (sv) => {//下拉框的值转换为空
    return sv <= 0 ? "" : sv;
}


common.settreedata = (listdata, levkey = "lev", lev = 0, pidkey = "pid", pid = 0) => {
    let treedata = [];
    if (!basic.chempty(listdata)) {
        treedata = listdata.filter(item => (item[levkey] == lev) && (item[pidkey] == pid));
        common.each(treedata, item => {
            //item.x_children = common.settreedata(listdata, levkey, lev + 1, pidkey, item.id);
            //item.x_haschildren = common.basic.isarrayexist(item.x_children) ? 1 : -1;

            Vue.set(item, "x_children", common.settreedata(listdata, levkey, lev + 1, pidkey, item.id));
            Vue.set(item, "x_haschildren", common.basic.isarrayexist(item.x_children) ? 1 : -1);
        });
    }
    return treedata;
};


common.getrowbyindex = (treedata, rowindex = -1) => {

    let itemfind = null;
    if (rowindex >= 0) {
        treedata.forEach(item => {
            if (!itemfind) {
                if (item.x_rowindex == rowindex) {
                    itemfind = item;
                }
                else {
                    if (basic.isarrayexist(item.x_children)) {
                        itemfind = common.getrowbyindex(item.x_children, rowindex);
                    }
                }
            }

        });
    }

    return itemfind
}

common.getrowbyid = (treedata, id) => {
    return common.getrowbyfn(treedata, "id", id);
}

common.getrowbyfn = (treedata, fn, v) => {
    let itemfind = null;
    treedata.forEach(item => {
        if (!itemfind) {
            if (item[fn] == v) {
                itemfind = item;
            }
            else {
                if (basic.isarrayexist(item.x_children)) {
                    itemfind = common.getrowbyfn(item.x_children, fn, v);
                }
            }
        }

    });
    return itemfind
}


////////////////////Ajax
common.fetch = (jsondata, callback, endback) => {
    var defaultdata = {
        'msg': 'Loading', 'isautoback': true, 'exts': '', 'api': FFAPI, 'act': '', 'needid': false
    };
    jsondata = Object.assign(defaultdata, jsondata);
    let randlink = 'dt=' + (new Date()).getTime();
    jsondata.exts = jsondata.exts == '' ? randlink : randlink + "&" + jsondata.exts;

    if (jsondata.api != '') { jsondata.api += '/'; }
    let myloading = common.loading(jsondata.msg);
    ajax({
        url: '/api.ashx',
        method: 'get',
        params: {
            'url': jsondata.api + jsondata.act,
            'exts': jsondata.exts
        }
    }).then(res => {
        common.loadingclose(myloading);
        if ((process.env.NODE_ENV == 'debug') || (process.env.NODE_ENV == 'development')) {
            console.log(jsondata, res);
        }
        if (jsondata.isautoback) {
            if (endback != undefined) {
                endback();
            }
            if (res.bv == 1) {
                if (callback != undefined) {
                    res.data = basic.chempty(res.data) ? {} : JSON.parse(res.data);
                    if (jsondata.needid) {
                        if (res.data.infodata.id) {
                            callback(res.data);
                        }
                        else {
                            window.location = '/';
                        }
                    }
                    else {
                        callback(res.data);
                    }

                }
            }
            else {
                common.alert(res.msg);
            }
        }
        else {
            callback(res)
        }
    })
        .catch((err) => {
            common.loadingclose(myloading);
            common.alert('errinternal');
            if ((process.env.NODE_ENV == 'debug') || (process.env.NODE_ENV == 'development')) {
                console.log(err);
            }
        });
};

common.post = (jsondata, callback, endback) => {
    var defaultdata = { 'msg': 'Loading', 'isautoback': true, 'needid': false };
    jsondata = Object.assign(defaultdata, jsondata);
    if (basic.isobjexist(jsondata.postdata.data)) {
        jsondata.postdata.data = basic.replaceplus(JSON.stringify(jsondata.postdata.data));
    }
    if (basic.isobjexist(jsondata.postdata.exts)) {
        jsondata.postdata.exts = basic.replaceplus(JSON.stringify(jsondata.postdata.exts));
    }
    if (basic.chempty(jsondata.postdata.api)) { jsondata.postdata.api = FFAPI; }
    if (basic.chempty(jsondata.postdata.act)) { jsondata.postdata.act = FFACT; }
    let myloading = common.loading(jsondata.msg);
    ajax({
        url: '/api.ashx',
        method: 'post',
        data: jsondata.postdata
    }).then(res => {
        common.loadingclose(myloading);
        if ((process.env.NODE_ENV == 'debug') || (process.env.NODE_ENV == 'development')) {
            console.log(jsondata, res);
        }
        if (jsondata.isautoback) {
            if (endback != undefined) {
                endback();
            }
            if (res.bv == 1) {
                if (callback != undefined) {
                    res.data = basic.chempty(res.data) ? {} : JSON.parse(res.data);
                    let datadefault = { "cpage": 1, "ps": 15, "pagemax": 1, "recordcount": 1 }
                    res.data = Object.assign({}, datadefault, res.data);

                    if (jsondata.needid) {
                        if (res.data.infodata.id) {
                            callback(res.data);
                        }
                        else {
                            window.location = '/';
                        }
                    }
                    else {
                        callback(res.data);
                    }
                }
            }
            else {
                if (res.bv <= 0) {
                    switch (res.bv) {
                        case -1000:
                            res.msg = 'needlogin';
                            store.dispatch('setislogin', 0);
                            window.location = '/';
                            break;
                        case -1001:
                            res.msg = 'Illegaloperation';
                            window.location = '/';
                            break;
                        default:
                            common.alert(res.msg);
                            break;
                    }
                }


            }
        }
        else {
            callback(res)
        }
    })
        .catch((err) => {
            common.loadingclose(myloading);
            common.alert('errinternal');
            if ((process.env.NODE_ENV == 'debug') || (process.env.NODE_ENV == 'development')) {
                console.log(err);
            }
        });
};


///////////前端方法///////////

common.logout = () => {
    common.fetch({ 'act': 'logout', 'msg': '' }, () => {
        store.dispatch('setislogin', 0);
        window.location = '/';
    });
};

common.telev = 5;

common.courseinfotype = {
    Content: (v) => v == 1,
    Info: (v) => v >= 2,
    Guide: (v) => v == 2,
    Resource: (v) => v == 3,
    Assist: (v) => v == 4,
    Writing: (v) => v == 5 || v == 6
}

common.checklogin = () => {
    if (store.state.islogin == 0) {
        //router.replace('/');
        window.location = '/';
        return false;
    }
    return true;
}

common.markmobile = (mobile) => {
    let _mymobile = '';
    if (mobile) {
        if (mobile.length > 8) {
            _mymobile = mobile.substring(0, 3) + '****' + mobile.substring(7);
        }
    }
    return _mymobile;
}


common.wirtingstat = (v) => common.dicword('infohwstatus' + v);

common.getface = (v) => v || '/include/images/facedefault.png';


export default common
