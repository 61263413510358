//import Vue from 'vue';


const basic = {
    chempty: (v) => ((v == '') || (v == null) || (v == 'null') || (v == undefined) || (v.length == 0)),
    chbool: (v, dv = true) => v == undefined ? dv : v,
    tranempty: (str, v) => basic.chempty(str) ? v : str,
    isarrayexist: (arr) => (arr || []).length > 0,
    isobjexist: (obj) => {
        if (obj) {
            let name;
            for (name in obj) {
                return true;
            }
            return false;
        }
        return false;
    },
    jsonclone: (obj) => basic.isobjexist(obj) ? JSON.parse(JSON.stringify(obj)) : {},
    ffjsonparse: (v) => basic.chempty(v) ? {} : JSON.parse(v),
    ffassign: (objdefault, obj) => {
        let obj2 = basic.jsonclone(objdefault);
        Object.keys(obj2).forEach(item => {
            if (obj[item] == undefined) {
                //Vue.set(obj, item, obj2[item]);
                obj[item] = obj2[item];
            }
        })
    },
    ffassignforce: (objdefault, obj) => {//强制使用默认值
        let obj2 = basic.jsonclone(objdefault);
        Object.keys(obj2).forEach(item => {
            obj[item] = obj2[item];
        })
    },
    ffextend: extend,
    fffind: (arr, key, v) => {
        if (basic.isarrayexist(arr)) {
            let objindex = arr.findIndex(item => item[key] == v);
            return objindex >= 0 ? arr[objindex] : {};
        }
        else {
            return {};
        }
    },
    fffind2: (arr, key, v, key2, v2) => {
        if (basic.isarrayexist(arr)) {
            let objindex = arr.findIndex(item => item[key] == v && item[key2] == v2);
            return objindex >= 0 ? arr[objindex] : {};
        }
        else {
            return {};
        }
    },
    fffilter: (arr, key, v) => {
        if (basic.isarrayexist(arr)) {
            return arr.filter(item => item[key] == v) || [];
        }
        else {
            return [];
        }
    },
    fffilter2: (arr, key, v, key2, v2) => {
        if (basic.isarrayexist(arr)) {
            return arr.filter(item => item[key] == v && item[key2] == v2) || [];
        }
        else {
            return [];
        }
    },
    ffobjreplace: (obj, newobj) => {//新数据替换旧数据
        let obj2 = basic.jsonclone(newobj);
        Object.keys(obj).forEach(item => {
            if (obj2[item] != undefined) {
                obj[item] = obj2[item];
            }
        })
    },
    ffcontain: (ids, id) => (',' + ids + ',').indexOf(',' + id + ',') >= 0,
    ffidremove: (ids, id) => {
        let myids = ids.split(',');
        let newids = [];
        for (var i = 0; i < myids.length; i++) {
            if (myids[i] != id) {
                newids.push(myids[i]);
            }
        }
        return newids.join();
    },
    ffidadd: (ids, id) => {
        return ids + (ids ? ',' : '') + id;
    },
    ffidselect: (ids, id) => {
        if (basic.ffcontain(ids, id)) {
            return basic.ffidremove(ids, id);
        }
        else {
            return basic.ffidadd(ids, id);
        }
    },
    ffremove: (arr, key, v) => {
        if (basic.isarrayexist(arr)) {
            let _arr = basic.jsonclone(arr);
            arr = [];
            _arr.forEach(item => {
                if (item[key] != v) {
                    arr.push(item);
                }
            });
        }
        return arr;
    },
    ffreplaceall: (str, findstr, placestr) => {
        try {
            let v = str.toString();
            return v.replaceAll(findstr, placestr);
        } catch (err) {
            return str;
        }
    },
    padleft: (num, n = 2, v = '0') => {
        let len = num.toString().length;
        while (len < n) {
            num = v + num;
            len++;
        }
        return num;
    },
    datetran: (str) => datefix(str),
    dateadd: (datenow, interval, number) => {

        let cd = basic.datetran(datenow);

        switch (interval) {
            case "y":
                return new Date(cd.setFullYear(cd.getFullYear() + number));
            case "m":
                return new Date(cd.setMonth(cd.getMonth() + number));
            case "d":
                return new Date(cd.setDate(cd.getDate() + number));
            case "w":
                return new Date(cd.setDate(cd.getDate() + 7 * number));
            case "h":
                return new Date(cd.setHours(cd.getHours() + number));
            case "n":
                return new Date(cd.setMinutes(cd.getMinutes() + number));
            case "s":
                return new Date(cd.setSeconds(cd.getSeconds() + number));
            case "l":
                return new Date(cd.setMilliseconds(cd.getMilliseconds() + number));
        }
    },
    datediff: (dateold, interval, datenew) => {
        datenew = basic.datetran(datenew);
        let d = basic.datetran(dateold),
            i = {},
            t = d.getTime(),
            t2 = datenew.getTime();
        i['y'] = datenew.getFullYear() - d.getFullYear();
        i['q'] = i['y'] * 4 + Math.floor(datenew.getMonth() / 4) - Math.floor(d.getMonth() / 4);
        i['m'] = i['y'] * 12 + datenew.getMonth() - d.getMonth();
        i['ms'] = datenew.getTime() - d.getTime();
        i['w'] = Math.floor((t2 + 345600000) / (604800000)) - Math.floor((t + 345600000) / (604800000));
        i['d'] = Math.floor(t2 / 86400000) - Math.floor(t / 86400000);
        i['h'] = Math.floor(t2 / 3600000) - Math.floor(t / 3600000);
        i['n'] = Math.floor(t2 / 60000) - Math.floor(t / 60000);
        i['s'] = Math.floor(t2 / 1000) - Math.floor(t / 1000);
        return i[interval];

    },
    dateformat: (str, df = 'yyyy-MM-dd') => {
        let dt = basic.datetran(str);
        return (dt == "") ? "" : dt.format(df);
    },
    chint2time: (v) => {
        let ch = basic.padleft(Math.floor(v / 60));
        let cm = basic.padleft(Math.floor(v - (ch * 60)));
        return ch + ":" + cm;
    },
    chint2timewithsecond: (v) => {
        let ch = basic.padleft(Math.floor(v / 3600));
        let remain = v - (ch * 3600);
        let cm = basic.padleft(Math.floor(remain / 60));
        let cs = basic.padleft(Math.floor(remain - (cm * 60)));
        return ch + ":" + cm + ":" + cs;
    },
    chint2bool: (v) => v == 1 ? 'yes' : 'no',
    chstr2int: (str, dv = 0) => {
        try {
            let nv = parseInt(str);
            if (isNaN(nv)) { nv = 0; }
            return basic.chempty(str) ? dv : nv;
        } catch (err) {
            return dv;
        }
    },
    chtime2int: (str) => {
        let temp = 0;
        try {
            if (!basic.chempty(str)) {
                let ts = str.split(":");
                temp = (ts.length == 2) ? Number(ts[0] * 60) + Number(ts[1]) : (ts.length == 3) ? Number(ts[0] * 60 * 60) + Number(ts[1] * 60) + Number(ts[2]) : 0;
            }
        } catch (err) {
            //
        }
        return temp;
    },
    chint2letter: (v) => {
        //let chweek = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', '', ''];
        const choiceletter = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        return (choiceletter[v]);
    },
    chint2ip: (n) => {
        var str = "";
        var tt = new Array();
        tt[0] = (n >>> 24) >>> 0;
        tt[1] = ((n << 8) >>> 24) >>> 0;
        tt[2] = (n << 16) >>> 24;
        tt[3] = (n << 24) >>> 24;
        str = String(tt[0]) + "." + String(tt[1]) + "." + String(tt[2]) + "." + String(tt[3]);
        return str;
    },
    chip2int: (ip) => {
        var num = 0;
        ip = ip.split(".");
        num = Number(ip[0]) * 256 * 256 * 256 + Number(ip[1]) * 256 * 256 + Number(ip[2]) * 256 + Number(ip[3]);
        num = num >>> 0;
        return num;
    },
    chgender: (v) => v == 1 ? 'male' : v == 2 ? 'female' : 'genderprivate',
    tranchweek: (num) => {
        let chweek = ['一', '二', '三', '四', '五', '六', '日'];
        return (chweek[num - 1]);
    },
    tranroman: (num) => {
        let ans = "";
        let k = Math.floor(num / 1000);
        let h = Math.floor((num % 1000) / 100);
        let t = Math.floor((num % 100) / 10);
        let o = num % 10;
        let one = ['I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX'];
        let ten = ['X', 'XX', 'XXX', 'XL', 'L', 'LX', 'LXX', 'LXXX', 'XC'];
        let hundred = ['C', 'CC', 'CCC', 'CD', 'D', 'DC', 'DCC', 'DCCC', 'CM']
        let thousand = 'M';
        for (let i = 0; i < k; i++) {
            ans += thousand;
        }
        if (h)
            ans += hundred[h - 1];
        if (t)
            ans += ten[t - 1];
        if (o)
            ans += one[o - 1];
        return ans;
    },
    replaceplus: (str) => {
        let temp = str;
        if (!basic.chempty(str)) {
            temp = basic.ffreplaceall(temp, "\\+", "%2b");
            temp = basic.ffreplaceall(temp, "%2b", "#FFPLUSFFPLUSFF#");
            temp = basic.ffreplaceall(temp, "=", '%3D');
        }
        return temp;
    },
    strunformat: (str) => {
        let temp = str;
        if (!basic.chempty(str)) {
            temp = basic.ffreplaceall(temp, "&#39;", "'");
            temp = basic.ffreplaceall(temp, "&#45;", "-");
            temp = basic.ffreplaceall(temp, "&#40;", "(");
            temp = basic.ffreplaceall(temp, "&#41;", ")");
            temp = basic.ffreplaceall(temp, "&lt;", "<");
            temp = basic.ffreplaceall(temp, "&gt;", ">");
            temp = basic.ffreplaceall(temp, "&quot;", "\"");
            temp = basic.ffreplaceall(temp, "&#47;", "/");
            temp = basic.ffreplaceall(temp, "&#92;", "\\");
            temp = basic.ffreplaceall(temp, "&#91;", "[");
            temp = basic.ffreplaceall(temp, "&#93;", "]");
            temp = basic.ffreplaceall(temp, "&#123;", "{");
            temp = basic.ffreplaceall(temp, "&#125;", "}");
            temp = basic.ffreplaceall(temp, "&#124;", "|");
            temp = basic.ffreplaceall(temp, "&#44;", ",");

        }
        return temp;
    },
    strunformatline: (str) => {
        let temp = str;
        if (!basic.chempty(str)) {
            temp = basic.strunformat(temp);
            temp = basic.ffreplaceall(temp, "<br/>", "<br>");
            temp = basic.ffreplaceall(temp, "<br />", "<br>");
            temp = basic.ffreplaceall(temp, "<br>", "\n");
        }
        return temp;
    },
    strremovehtml: (str) => { //去除html标签：
        let temp = str;
        if (!basic.chempty(str)) {
            temp = basic.strunformat(str);
            temp = basic.ffreplaceall(temp, '&nbsp;', '');
            temp = temp.replace(/<[^>]+>/g, "");
        }
        return temp;
    },
    streditor2area: (str) => basic.strremovehtml(basic.strunformatline(str)),
    strarea2editor: (str) => basic.ffreplaceall(basic.ffreplaceall(str, "\r\n", "<br />"), "\n", "<br />"),
    strgetlen: (str, maxlen) => basic.strgetlen3x(str, maxlen * 1.5),
    strgetlen3x: (str, maxlen) => { //截取字符串

        if (!str) {
            return '';
        }
        str = basic.strremovehtml(str);
        let txtarr = Array.from(str);

        if (txtarr.length * 3 <= maxlen) {
            return str;
        }

        let txtlen = 0;
        let i = 0;

        for (i = 0; i < txtarr.length; i++) {
            let c = txtarr[i];
            txtlen += encodeutf8(c);
            if (txtlen > maxlen) { break; }
        }
        let newtxt = '';
        if (i >= txtarr.length - 1) {
            newtxt = str;
        }
        else {
            let newtxtarr = txtarr.splice(0, i);

            for (var j = 0; j < newtxtarr.length; j++) {
                newtxt += newtxtarr[j];
            }
            newtxt += "...";
        }

        return newtxt;
    },
    ffformat: (str, arr) => {
        var result = str;
        for (var i = 0; i < arr.length; i++) {
            var reg = new RegExp("\\{" + i + "\\}", "g");
            result = result.replace(reg, arr[i]);
        }
        return result;
    },
    ffinputline: (str) => str ? basic.ffreplaceall(str, '#LINE#', '&nbsp;<span class="inputline"></span>&nbsp;') : '',
    myencode: (str) => encodeURIComponent(basic.ffreplaceall(str, "\\+", "#FFPLUSFFPLUSFF#")),
    mydecode: (str) => basic.ffreplaceall(basic.ffreplaceall(decodeURIComponent(str), "\\+", " "), "#FFPLUSFFPLUSFF#", "+"),
    geturllast: (url) => basic.chempty(url) ? "" : url.substring(url.lastIndexOf("/") + 1),//根据A标签的HREF获取值
    getfiletype: (filename) => {
        let filetype = 99;
        if (filename.indexOf('.') > 0) {
            let extname = filename.substring(filename.lastIndexOf('.')).toLowerCase();
            if (basic.ffcontain(".jpg,.jpeg,.bmp,.png,.gif", extname)) { filetype = 1; }
            else if (basic.ffcontain(".txt,.aspx,.cs,.svc,.vue,.cshtml.ashx,.csv,.htm,.html,.js,.css,.csproj,.config,.xml", extname)) { filetype = 2; }
            else if (basic.ffcontain(".mp4", extname)) { filetype = 3; }
            else if (basic.ffcontain(".mp3", extname)) { filetype = 4; }
            else if (basic.ffcontain(".pdf", extname)) { filetype = 5; }
            else if (basic.ffcontain(".doc,.docx", extname)) { filetype = 6; }
            else if (basic.ffcontain(".xls,.xlsx", extname)) { filetype = 7; }
        }
        return filetype;
    },
    browser: {
        userAgent: navigator.userAgent,
        isopera: navigator.userAgent.indexOf("Opera") > -1,
        isie: (navigator.userAgent.indexOf("compatible") > -1 && navigator.userAgent.indexOf("MSIE") > -1 && navigator.userAgent.indexOf("Opera") <= -1) || navigator.userAgent.indexOf("rv:11") > -1,
        ischrome: navigator.userAgent.indexOf("Chrome") > -1,
        isedge: navigator.userAgent.indexOf("Trident") > -1,
        issafari: navigator.userAgent.indexOf("Safari") > -1,
        isff: navigator.userAgent.indexOf("Firefox") > -1,
        isios: !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/),
        iswebkit: navigator.userAgent.indexOf("MSIE") <= -1 && navigator.userAgent.indexOf("rv:11") <= -1 && (navigator.userAgent.indexOf("Chrome") > -1 || navigator.userAgent.indexOf("Trident") > -1 || navigator.userAgent.indexOf("Safari") > -1 || navigator.userAgent.indexOf("Firefox") > -1)
    }
};

export default basic


let datefix = (str) => {
    if (str instanceof Date) { //如果已经是日期
        return str;
    } else {
        let dtstr = basic.tranempty(str) || '1900-1-1';
        dtstr = dtstr.replace("T", " ")
        if (dtstr.lastIndexOf(".") > 0) {
            dtstr = dtstr.substring(0, dtstr.lastIndexOf("."));
        }
        dtstr = basic.ffreplaceall(dtstr, "-", "/");
        let cd = new Date(dtstr);
        return cd;
    }
}

String.prototype.replaceAll = function (AFindText, ARepText) { //全部替换
    return this.replace(new RegExp(AFindText, "gm"), ARepText);
}

String.prototype.dateformat = function (df = 'yyyy-MM-dd') {
    let dt = datefix(this);
    return dt.format(df);
}

Date.prototype.format = function (format = 'yyyy-MM-dd') {
    let o = {
        "M+": this.getMonth() + 1, // month
        "d+": this.getDate(), // day
        "h+": this.getHours(), // hour
        "m+": this.getMinutes(), // minute
        "s+": this.getSeconds(), // second
        "q+": Math.floor((this.getMonth() + 3) / 3), // quarter
        "S": this.getMilliseconds() // millisecond
    }
    if (/(y+)/.test(format)) {
        format = format.replace(RegExp.$1, (this.getFullYear() + "").substr(4 -
            RegExp.$1.length));
    }

    for (let k in o) {
        if (new RegExp("(" + k + ")").test(format)) {
            format = format.replace(RegExp.$1, RegExp.$1.length == 1 ?
                o[k] :
                ("00" + o[k]).substr(("" + o[k]).length));
        }
    }
    if (format.indexOf("1900-") >= 0) {
        format = "";
    }
    return format;
}


var class2type = {};
var mytoString = class2type.toString;
var hasOwn = class2type.hasOwnProperty;
var support = {};

function extend() {
    var src, copyIsArray, copy, name, options, clone,
        target = arguments[0] || {},
        i = 1,
        length = arguments.length,
        deep = false;

    // Handle a deep copy situation
    if (typeof target === "boolean") {
        deep = target;

        // skip the boolean and the target
        target = arguments[i] || {};
        i++;
    }

    // Handle case when target is a string or something (possible in deep copy)
    if (typeof target !== "object" && !isFunction(target)) {
        target = {};
    }

    // extend jQuery itself if only one argument is passed
    if (i === length) {
        target = this;
        i--;
    }

    for (; i < length; i++) {
        // Only deal with non-null/undefined values
        if ((options = arguments[i]) != null) {
            // Extend the base object
            for (name in options) {
                src = target[name];
                copy = options[name];

                // Prevent never-ending loop
                if (target === copy) {
                    continue;
                }

                // Recurse if we're merging plain objects or arrays
                if (deep && copy && (isPlainObject(copy) || (copyIsArray = isArray(copy)))) {
                    if (copyIsArray) {
                        copyIsArray = false;
                        clone = src && isArray(src) ? src : [];

                    } else {
                        clone = src && isPlainObject(src) ? src : {};
                    }

                    // Never move original objects, clone them
                    target[name] = extend(deep, clone, copy);

                    // Don't bring in undefined values
                } else if (copy !== undefined) {
                    target[name] = copy;
                }
            }
        }
    }

    // Return the modified object
    return target;
}

// isPlainObject isFunction isArray isWindow type

function isPlainObject(obj) {
    var key;

    // Must be an Object.
    // Because of IE, we also have to check the presence of the constructor property.
    // Make sure that DOM nodes and window objects don't pass through, as well
    if (!obj || type(obj) !== "object" || obj.nodeType || isWindow(obj)) {
        return false;
    }

    try {
        // Not own constructor property must be Object
        if (obj.constructor &&
            !hasOwn.call(obj, "constructor") &&
            !hasOwn.call(obj.constructor.prototype, "isPrototypeOf")) {
            return false;
        }
    } catch (e) {
        // IE8,9 Will throw exceptions on certain host objects #9897
        return false;
    }

    // Support: IE<9
    // Handle iteration over inherited properties before own properties.
    if (support.ownLast) {
        for (key in obj) {
            return hasOwn.call(obj, key);
        }
    }

    // Own properties are enumerated firstly, so to speed up,
    // if last one is own, then all properties are own.
    // for (key in obj) { }

    return key === undefined || hasOwn.call(obj, key);
}


function isFunction(obj) {
    return type(obj) === "function";
}

function isArray(obj) {
    return type(obj) === "array";
}

function isWindow(obj) {
    /* jshint eqeqeq: false */
    return obj != null && obj == obj.window;
}

function type(obj) {
    if (obj == null) {
        return obj + "";
    }
    return typeof obj === "object" || typeof obj === "function" ?
        class2type[mytoString.call(obj)] || "object" :
        typeof obj;
}
function encodeutf8(txt) {
    let code = encodeURIComponent(txt);
    let bytes = [];

    for (var i = 0; i < code.length; i++) {
        let c = code.charAt(i);
        if (c === '%') {
            let hex = code.charAt(i + 1) + code.charAt(i + 2);
            let hexval = parseInt(hex, 16);
            bytes.push(hexval);
            i += 2;
        }
        else {
            bytes.push(code.charCodeAt(0));
        }
    }
    return bytes.length <= 1 ? 2 : bytes.length;
}
